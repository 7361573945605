<template>
  <div v-if="task">
    <div
      class="bg-gray-100 rounded-lg p-3 h-fit cursor-pointer"
      @click.prevent="onClickTask"
    >
      <a :href="makeTaskUrl(task)" :draggable="false">
        <div class="bg-white rounded-lg shadow-md p-3">
          <p class="text-gray-900 font-medium" :style="{ wordBreak: 'break-word' }">{{ task.name }}</p>

          <div
            v-if="task.type"
            class="mt-3 flex items-center gap-0.5 bg-gray-100 rounded-md px-1.5 py-0.5 w-fit"
          >
            <TaskTypeIcon
              transparent
              :icon="task.type.icon"
              icon-class="text-gray-700"
              :size-wrapper="false"
            />
            <Tooltip :text="task.type.name">
              <template #default="{ getTextRef }">
                <span :ref="getTextRef" class="text-xs line-clamp-1 break-all">{{ task.type.name }}</span>
              </template>
            </Tooltip>
          </div>
          <div
            v-if="task.assignee || task.dueDate || task.startDate || task.status "
            class="mt-3 flex items-center gap-1 flex-wrap justify-start"
          >
            <UTooltip v-if="task.assignee" :text="task.assignee.fullName">
              <Avatar :id="task.assignee.id" :src="task.assignee.photo" :name="task.assignee.fullName" size="xs" />
            </UTooltip>
            <div
              v-if="task.startDate || task.dueDate"
              class="bg-gray-100 rounded-full px-2 py-1 text-xs font-medium flex items-center gap-1 shrink truncate"
            >
              <Icon name="heroicons:calendar" :size="16" />
              <TaskDatesFormat
                class="truncate"
                :show-time="false"
                :start="task.startDate"
                :end="task.dueDate"
              />
            </div>
            <StatusBadge
              v-if="task.status"
              class="flex items-center gap-1 text-xs hover:!border-inherit"
              :type="task.status.type"
              :label="task.status.name"
            />
          </div>
        </div>
        <div class="mt-2.5 text-xs">
          <Tooltip :text="task.board.name" :description="task.section.name">
            <template #default="{ getTextRef }">
              <span :ref="getTextRef" class="line-clamp-1 break-all">
                <span class="text-gray-900 font-bold ">
                  {{ task.board.name }}
                </span>
              </span>
            </template>
          </Tooltip>
        </div>

      </a>
    </div>
    <p
      class="mt-2 underline text-gray-900 font-medium cursor-pointer text-xs"
      @click="deleteAttachment(attachment.id)"
    >
      Remove
    </p>
  </div>
</template>

<script lang="ts" setup>
import type { TaskAttachmentResolver, TaskDetail } from '#task/types'
import { TASK_ATTACHMENT_PREVIEW_QUERY } from '#task/schema'

const props = defineProps({
  attachment: {
    type: Object as PropType<TaskAttachmentResolver>,
    required: true,
  },
  taskId: {
    type: String,
    required: true,
  },
})

const { setCurrentTask } = useWorkspaceSharedState()
const { deleteAttachment } = useUpdateTask()
const { result, load, stop } = useLazyQuery<{ taskPreview: TaskDetail }>(
  TASK_ATTACHMENT_PREVIEW_QUERY,
  {
    handle: props.attachment.resolver.handle,
  },
  {
    fetchPolicy: 'cache-first',
  }
)

const task = computed(() => result.value?.taskPreview)

const onClickTask = () => {
  if (task.value) {
    updateTaskUrl(task.value)
    setCurrentTask(task.value)
  }
}

onMounted(() => {
  load()
})

onUnmounted(() => {
  stop()
})
</script>
